@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 2px;
  max-height: 50px;
  background: rgba(100, 100, 100, 0.4);
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: rgba(250, 250, 250, 0.4);
}

/* hide scroll bar */
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  max-height: 50px;
  background: rgba(100, 100, 100, 0);
}

.hide-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(250, 250, 250, 0);
}

.client-img {
  transition: transform 1s linear;
}

.my-gradient-text{
  color: transparent;
  background: linear-gradient(90deg, #22a382, #29bad9, #e3b443,#bc4ccb, #c8390b);
  background-clip: text;
}
